<template>
  <div class="profile">
    <el-row style="margin-bottom: 20px;"
            type="flex"
            justify="space-between">
      <el-col :span="6">
        <h2>个人资料</h2>
      </el-col>

      <el-col :span="6">
        <el-button icon="el-icon-unlock"
                   size="small"
                   type="danger"
                   @click="editPassword">修改密码</el-button>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :span="24">
        <!-- 上传用户头像 -->
        <!-- <span class="avatar-img"><img :src="form.memPic || '/img/default_avatar.png'"
               alt=""></span> -->
        <el-upload class="avatar-img"
                   :headers="headers"
                   :action="BaseUrl + '/codeStoreFrontAdmin/uploadAvatar'"
                   :show-file-list="false"
                   :on-success="handleAvatarSuccess"
                   :before-upload="beforeAvatarUpload">
          <img :src="form.memPic || '/img/default_avatar.png'">
        </el-upload>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :span="18">

        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="80px">

          <el-form-item label="用户名">
            <el-input v-model="form.memName"
                      disabled></el-input>
          </el-form-item>

          <el-form-item label="昵称">
            <el-input v-model="form.memNickname"></el-input>
          </el-form-item>

          <!-- <el-form-item label="个人介绍">
            <el-input v-model="form.introduce"></el-input>
          </el-form-item> -->

          <el-form-item label="邮箱">
            <el-input v-model="form.memEmail"
                      disabled></el-input>
          </el-form-item>

          <el-form-item label="手机号">
            <el-input v-model="form.memPhone"
                      disabled></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary"
                       @click="onSubmit">修 改</el-button>
            <el-button @click="reset">重 置</el-button>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>

  </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import { getUserInfo, updateUserInfo } from '@/api/profile.js'
import { getToken } from '@/utils/auth'
export default {
  name: 'profile',
  data () {
    return {
      headers: { Authorization: 'Bearer ' + getToken() },
      form: {
        memPic: '',
        memName: '',
        memNickname: '',
        memEmail: '',
        memPhone: ''
      },
      rules: {
        memNickname: [
          { required: true, trigger: 'blur', message: '用户昵称必须' }
        ]
      }
    }
  },
  methods: {
    // 跳转到修改密码
    editPassword () {
      this.$router.push({ path: 'editPassword' })
    },
    handleAvatarSuccess (res, file) {
      // console.log(res, file)
      const { code, data } = res
      if (code === 0) {
        const { fileUrl } = data
        this.form.memPic = fileUrl
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    onSubmit () {
      // console.log('submit!')

      this.$refs.form.validate(valid => {
        if (valid) {
          updateUserInfo(this.form).then(res => {
            const { code, msg } = res
            if (code === 0) {
              this.$message.success(msg)
            } else {
              this.$message.error(msg || '用户信息修改失败!')
            }
          })
        }
      })
    },
    reset () {
      this.form.memNickname = ''
    }
  },
  created () {
    getUserInfo().then(res => {
      const { code, data, msg } = res
      if (code === 0) {
        const {
          memPic,
          memName,
          memNickname,
          memEmail,
          memPhone
        } = data

        this.form.memPic = memPic
        this.form.memName = memName
        this.form.memNickname = memNickname
        this.form.memEmail = memEmail
        this.form.memPhone = memPhone
      } else {
        this.$message.error(msg || '获取用户信息失败!')
      }
    })
  }
}
</script>

<style lang="less" scoped>
.panel-page .panel-heading {
  background: transparent;
  border-bottom: none;
  margin: 0 0 30px 0;
  padding: 0;
}

.panel-page .panel-heading h2 {
  font-size: 25px;
  margin-top: 0;
}

.pull-right {
  float: right !important;
}

.avatar-img {
  display: inline-block;
  box-sizing: content-box;
  color: #fff;
  text-align: center;
  vertical-align: top;
  font-weight: normal;

  margin-left: 110px;
  margin-bottom: 40px;
}

.avatar-img img {
  height: 100px;
  width: 100px;
  border-radius: 150px;
  vertical-align: middle;
  border: 0;
}
</style>
