import request from '@/utils/request'

// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/codeStoreFrontAdmin/userInfo',
    method: 'get'
  })
}

// 修改用户信息
export function updateUserInfo (data) {
  return request({
    url: '/codeStoreFrontAdmin/updateUserInfo',
    method: 'post',
    data: data
  })
}

// 修改用户密码
export function updateUserPassword (data) {
  return request({
    url: '/codeStoreFrontAdmin/updateUserPassword',
    method: 'post',
    data: data
  })
}
